.menu .link {
  display: inline-block;
  @apply font-display;
  font-size: 34px;
  line-height: 34px;
  @apply font-normal;
  color: white;
  text-decoration: none;

  @apply lg:text-lg;

  @apply mb-2;
}