/* PDF */

.pdf-modal .content-grid {
    @apply grid grid-cols-3 grid-rows-2 h-full max-h-full;
    grid-template-rows: 1fr auto;
}

@screen lg {
    .pdf-modal .content-grid {
        grid-template-columns: auto 1fr auto;
    }
}

.pdf-modal .nav-btn-wrapper {
    @apply row-start-2 col-span-1 flex items-center lg:row-start-1;
}

.pdf-modal .nav-btn-wrapper button {
    @apply p-8;
}

.pdf-modal .nav-btn-wrapper button.disabled {
    @apply opacity-0 pointer-events-none;
}

.pdf-modal .nav-btn-wrapper.prev {
    @apply col-start-1 justify-start;
}

.pdf-modal .nav-btn-wrapper.next {
    @apply col-start-3 justify-end;
}

.pdf-modal .page-num-wrapper {
    @apply col-start-2 row-start-2 flex flex-col justify-center items-center lg:items-start text-center lg:pt-2 lg:pb-8;
}

.pdf-modal .open-in-hd {
    @apply font-bold text-xs mt-1;
}

.pdf-modal .open-in-hd > div {
    @apply flex;
}

.pdf-modal .open-in-hd svg {
    width: 18px;
    height: 18px;
    @apply mr-1;
    margin-top: 2px;
}

.pdf-modal .canvas-parent {
    @apply h-full w-full max-h-full max-w-full;
}

.pdf-modal .content {
    @apply col-start-1 row-start-1 lg:col-start-2 col-span-3 lg:col-span-1 relative py-12 lg:pt-12 lg:pb-2 flex-grow flex-shrink h-full max-h-full;
}

.pdf-modal .document {
    @apply absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center;
}

.pdf-modal .page-wrapper {
    @apply relative;
}

.pdf-page > canvas {
    background-color: black;
}
