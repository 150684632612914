.menu {
}

.menu .shade {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;

  background-color: black;
  opacity: 0;
  pointer-events: none;
  transition: opacity 400ms;
}

.menu.open .shade {
  opacity: 0.95;
  pointer-events: initial;
}

.menu .menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;

  height: 100%;

  transform: translateX(100%);
  transition: transform 400ms;
  pointer-events: none;
}

.menu.open .menu-wrapper {
  transform: translateX(0%);
}

.menu .project-spotlight {
  flex-grow: 1;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 50px;
}

.menu .project-spotlight .hero-image {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 600px;
  border: 1px solid #FFFFFF;
  margin-bottom: 0;
}

.menu .menu-content {
  flex-shrink: 0;
  pointer-events: initial;
  position: relative;
  background: black;
  border: 1.75px solid white;

  width: 100%;

  display: flex;
  flex-direction: column;
}

.menu .menu-content .glow {
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  pointer-events: none;
  opacity: 0;

  transition: opacity 400ms;
}

.menu.open .menu-content .glow {
  opacity: 0.2;
}

.menu .menu-content .glow-hoverout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.menu .close-icon {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
  opacity: 0;
  transform: rotate(45deg);
  transition: transform 400ms 200ms, opacity 400ms 200ms;
  width: 42px;
  height: 42px;
  z-index: 1;
}

.menu.open .close-icon {
  opacity: 1;
  transform: rotate(0deg);
}

.menu .menu-content .links {
  flex-grow: 1;
  position: relative;
  @apply mt-16;
  @apply px-8;
  @apply pb-5;
  overflow-y: auto;
  z-index: 0;
}

.menu .menu-content .link-list {
  /* @apply mt-16; */
}

.menu .menu-content .links label {
  display: block;
  @apply font-body;
  @apply text-tiny;
  @apply font-normal;
  text-align: left;

  color: white;

  @apply mb-5;
}

.menu .menu-content .links label:not(:first-of-type) {
  @apply mt-9;
}

@screen sm {
  .menu .menu-content {
    width: 480px;
  }

  .menu .menu-content .links {
    @apply px-12;
    @apply mt-20;
  }

  .menu .menu-content .links label:not(:first-of-type) {
    @apply mt-14;
  }
}

@screen lg {
  .menu .menu-content {
    width: 720px;
  }

  .menu .menu-content .links label {
    @apply text-base;
  }

  .menu .project-spotlight {
    display: flex;
  }

  .menu .close-icon {
    top: 50px;
    right: 50px;
    width: 52px;
    height: 52px;
  }
}