.info-callout {
    position: relative;
    min-width: 100px;
}

.info-callout > * {
    @apply pl-2.5;
    @apply lg:pl-5;
}

.info-callout label {
    @apply font-display;
    @apply text-xs;
    display: block;
    @apply mb-2;

    @apply md:text-tiny;
    @apply md:mb-2.5;

    @apply lg:text-base;
    /* @apply lg:mb-2.5; */
}

.info-callout .value {
    position: relative;
    text-align: left;

    @apply text-xxs;

    @apply md:text-xs;
    @apply lg:text-tiny;
}

.info-callout .value > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info-callout .value::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    content: "";
    border-left: 1px solid #FFFFFF;
}

@screen lg {
    .info-callout {
        min-width: 220px;
    }
}