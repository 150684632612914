.home-page {
  height: 100%;
  overflow: hidden;
}

@keyframes fadeInScene {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.home-page .scene-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
}

.home-page .scene-bg::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: black;

  animation: fadeInScene ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  pointer-events: none;
}

.home-page .page {
  position: relative;
  z-index: 1;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.home-page .menu-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 50px;
  margin-right: 50px;
  cursor: pointer;
  opacity: 1;
  transition: transform 500ms, opacity 500ms 100ms;
}

.home-page .menu-icon svg {
  width: 52px;
  height: 52px;
}

.home-page .menu-icon.menu-open {
  opacity: 0;
  transform: translateX(-200px);
}

.home-page .page .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.home-page .page .title {
  color: white;
  margin-top: 0;
  margin-bottom: 38px;

  font-family: Queens;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0em;
  text-align: center;

  font-size: 22vmin;
  line-height: 18vmin;
}

.home-page .page .subtitle {
  font-family: Moderat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  margin-left: 30px;
  margin-right: 30px;

  color: white;
  text-align: center;
}

.home-page footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

/* tablet or smaller */
@media screen and (max-width: 768px) {
  .home-page .page .title {
    font-size: 20vmin;
    line-height: 16vmin;
  }

  .home-page .page .subtitle {
    font-size: 19px;
    line-height: 23px;
  }

  .home-page .menu-icon {
    margin-top: 20px;
    margin-right: 20px;
  }

  .home-page .menu-icon svg {
    width: 42px;
    height: 42px;
  }
}
