@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  body {
    color: white;
    background-color: black;
    margin: 0;
    @apply font-body text-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    height: 100%;
    margin:0;
    padding:0;
  }

  body.modal-scroll-lock {
    overflow: hidden;
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}

@layer utilities {
  .pl-gutter {
    @apply pl-0;
    @apply lg:pl-12;
  }

  .pr-gutter {
    @apply pr-0;
    @apply lg:pr-12;
  }

  .px-gutter {
    @apply px-0;
    @apply lg:px-12;
  }

  .ml-gutter {
    @apply ml-0;
    @apply lg:ml-12;
  }

  .mr-gutter {
    @apply mr-0;
    @apply lg:mr-12;
  }

  .mx-gutter {
    @apply mx-0;
    @apply lg:mx-12;
  }
}