.featured-showcase-tooltip .hero-image {
    width: 425px;
    height: 512px;
    background: #000000;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 40px 5px #57C0A4;
}

.featured-showcase-tooltip label {
    display: block;
    margin-top: 20px;

    @apply font-body;
    @apply text-base;
    @apply font-normal;

    text-align: left;
}