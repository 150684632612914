.hero-image {
    position: relative;
}

.hero-image img {
    max-width: 100%;
    border: none;
}

.hero-image img.def {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
