.showcase {
    margin: 0 20px 0 20px;

    @apply font-body;
    @apply text-xs;
    @apply md:text-tiny;
    @apply lg:text-base;
    @apply font-normal;
}

.showcase nav {
    position: fixed;
    top: 45vh;
    left: 50px;
    right: 50px;
    display: none;
    z-index: 0;
    justify-content: space-between;
}

.showcase h1.title {
    @apply font-display;
    @apply text-lg;
    @apply font-extrabold;

    text-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2), 0px 5px 40px rgba(0, 0, 0, 0.3);
}

/* Content */

.showcase article h3 {
    @apply font-display;
    @apply text-base;
    @apply font-extrabold;
    @apply mb-2.5;

    @apply lg:mb-5;
    @apply lg:text-med;
}

.showcase article {
    position: relative;
    z-index: 1;
    margin: 0;
    display: block;
}

.showcase article a {
    @apply font-bold;
}

.showcase article section {
    @apply mb-14;
    @apply lg:mb-24;
}

.showcase footer {
    margin-top: 50px;
}

@screen md {
    .showcase {
        max-width: 710px;
        margin: 0 auto 0 auto;
    }
}


@screen lg {
    .showcase {
        max-width: 1100px;
    }

    .showcase h1.title {
        @apply text-xl;
    }

    .showcase nav {
        display: flex;
    }
}

/* @media screen and (max-width: 1364px) {
    .showcase {
        max-width: 840px;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 1100px) {
    .showcase nav {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .showcase {
        padding-left: 30px;
        padding-right: 30px;

        font-size: 19px;
        line-height: 23px;
    }

    .showcase h1.title {
        font-size: 60px;
        line-height: 77px;
    }
} */