.project .title-wrapper {
    position: relative;
    padding-top: 35vh;
    padding-bottom: 9vh;
    margin-bottom: 9vh;
}

.project h1.title {
    margin: 0;
}

.project .title-wrapper .hero-wrapper {
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    bottom: 0;
    z-index: -1;
}

.project .title-wrapper .hero-image {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: -1;

    border: 1px solid #FFFFFF;
}

@screen sm {
    .project .title-wrapper .hero-wrapper {
        left: 50px;
        right: 50px;
    }

    .project .title-wrapper {
        padding-top: 45vh;
    }
}

@screen lg {
    .project .title-wrapper {
        padding-top: 70vh;
        padding-bottom: 10vh;
        margin-bottom: 10vh;
    }
}
