header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 90;
    transition: background-color 500ms;
}

header a {
    color: white;
    text-decoration: none;
}

header h1 {
    @apply font-display;
    @apply font-extrabold;
    font-size: 28px;
    line-height: 36px;
    text-align: left;
    margin: 0;
}

header svg {
    cursor: pointer;
    width: 42px;
    height: 42px;
}

header.bg-active {
    background-color: black;
}

/* tablet or smaller */
/* @media screen and (max-width: 1100px) {
    header {
        padding: 20px;
    }

    header h1 {
        font-size: 28px;
        line-height: 36px;
    }

    header svg {
        width: 42px;
        height: 42px;
    }
} */

@screen lg {
    header.bg-active {
        background-color: transparent;
    }

    header {
        padding: 50px;
    }

    header h1 {
        font-size: 36px;
        line-height: 46px;
    }

    header svg {
        width: 52px;
        height: 52px;
    }
}