.collection h1.title {
    margin-top: 100px;
    margin-bottom: 30px;
}

.collection .hero-image {
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px;
}

@screen lg {
    .collection h1.title {
        margin-top: 154px;
    }

    .collection .hero-image {
        margin-bottom: 125px;
    }
}