footer {
    padding-bottom: 20px;
}

.copyright {
    margin-top: 50px;

    @apply font-body;
    @apply text-tiny;
    @apply lg:text-base;
    @apply font-normal;

    text-align: center;
}

@screen lg {
    footer {
        padding-bottom: 50px;
    }
}