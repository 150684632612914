.arrow {
    transform: translateX(0px);
    transition: transform 500ms;
}

.arrow.left svg {
    transform: scaleX(-1);
}

.arrow.left:hover {
    transform: translateX(-20px);
}

.arrow.right:hover {
    transform: translateX(20px);
}
