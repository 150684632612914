.showcase-subtitle {
    @apply font-display;
    /* @apply text-base; */
    @apply text-sub;
    @apply font-extrabold;

    margin-top: 0;
    @apply mb-6;

    /* @apply md:text-med; */

    /* @apply lg:text-lg;
    @apply lg:mb-12; */
}

@screen md {
    .showcase-subtitle {
        @apply md:text-med;
        line-height: 30px;
    }
}

@screen lg {
    .showcase-subtitle {
        @apply lg:text-lg;
        @apply lg:mb-12;
    } 
}