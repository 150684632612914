.about-page {
  height: 100%;
}

@keyframes fadeInScene {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.about-page .scene-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.about-page .scene-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: black;

  animation: fadeInScene ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  pointer-events: none;
}

.about-page .page {
  position: relative;
  z-index: 1;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.about-page .page .content {
  margin: 0 20px 0 20px;
  margin-top: 15vh;

  max-width: 530px;

  @apply font-body;
  @apply text-tiny;
  @apply font-normal;

  text-align: left;
}

.about-page .about-title {
  @apply font-display;
  @apply text-lg;
  @apply font-extrabold;

  text-align: left;

  margin-top: 0;
  @apply mb-10;
}

.about-page .about-description {
  @apply mb-8;
}

.about-page .social-link {
  display: block;
  text-decoration: none;
  color: white;
  margin: 5px;
  padding: 2px;
}

.about-page .social-link .social-icon {
  display: inline-block;
  vertical-align: middle;

  @apply w-12;
}

.about-page .social-link .social-icon svg {
  @apply w-7;
}

@screen sm {
  .about-page .page .content {
    margin-left: 50px;
  
    /* max-width: 400px; */
    max-width: 530px;
    @apply text-base;
  }

  .about-page .about-title {
    @apply text-xl;
    @apply mb-12;
  }
}

@screen md {
  .about-page .page .content {
    margin-left: 100px; /* FIXME */

    /* max-width: 530px; */
  }

  .about-page .about-description {
    @apply mb-10;
  }

  /* .about-page .about-title {
    @apply text-xl;
    @apply mb-12;
  } */

  .about-page .social-link {
    margin: 7px;
    padding: 3px;
  }

  .about-page .social-link .social-icon {
    @apply w-14;
  }

  .about-page .social-link .social-icon svg {
    @apply w-14;
  }
}

@screen lg {
  .about-page .page .content {
    margin-left: 200px;
  }
}