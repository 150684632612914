.showcase-description {
    @apply mb-20 !important;
    @apply pl-gutter;

    @apply md:mb-14 !important;
    @apply lg:mb-24 !important;
}

.showcase-description .date {
    @apply text-xxs;
    @apply mb-2;

    @apply md:text-xs;
    @apply md:mb-3;

    @apply lg:text-tiny;
    @apply lg:mb-5;
}

.showcase-description .showcase-subtitle {
    max-width: 780px;
}

.showcase-description .content {
    display: flex;
    flex-direction: column;
    @apply text-xs;
    margin-right: 10px;

    @apply md:text-tiny;
    @apply lg:text-base;
}

.showcase-description .info-callouts {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;

    @apply mt-11;
    @apply md:mt-6;

    @apply lg:mt-5;
    @apply lg:flex-col;
    @apply lg:justify-start;
}

.showcase-description .info-callouts.hide-on-mobile {
    display: none;
    @apply md:flex;
}

.showcase-description .info-callouts .info-callout {
    width: 33%;
}

@screen md {
    .showcase-description .content {
        flex-direction: row;
        margin-right: 0;
    }

    .showcase-description .info-callouts {
        @apply ml-28;
        @apply mt-0;
        flex-direction: column;
    }

    .showcase-description .info-callouts .info-callout {
        width: unset;
        @apply mb-8;
    }
}

@screen lg {
    .showcase-description .info-callouts {
        @apply ml-44;
        @apply mt-0;
    }
}